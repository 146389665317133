import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from './components/nav';
import { bandData } from './band-data';
import Home from './routes/Home';
import Bio from './routes/Bio';
import Events from './routes/Events';
import Pictures from './routes/Pictures';
import Videos from './routes/Videos';
import Media from './routes/Media';
import Merch from './routes/Merch';
import styled from 'styled-components/macro';
import './App.css';

const MainWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;

  max-width:1000px;
`;


export default function App() {

  const nav = [
    'bio',
    // 'pictures',
    'listen',
    'watch',
    'events',
    'merch',
  ];

  return (
    <Router>

      <Nav nav={nav} bandData={bandData} />
      <MainWrapper>

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path='/bio'>
            <Bio bandData={bandData} />
          </Route>
          <Route exact path='/events'>
            <Events bandData={bandData} />
          </Route>

          <Route exact path='/pictures'>
            <Pictures bandData={bandData} />
          </Route>

          <Route exact path='/watch'>
            <Videos bandData={bandData} />
          </Route>
          <Route exact path='/listen'>
            <Media bandData={bandData} />
          </Route>
          <Route exact path='/merch'>
            <Merch bandData={bandData} />
          </Route>

        </Switch>
      </MainWrapper>
    </Router>
  );
}
