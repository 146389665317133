import React, { useState } from 'react';
import styled from 'styled-components/macro';
import './videos.css';

const VideoPreviewContainer = styled.div`
  text-align:center;
  display:inline-block;
  margin:25px;  
`;

const VideoPreview = styled.div`
  display:inline-block;
  width:250px;
  background-color:black;
  border: 1px solid white;
  cursor: pointer;
  margin: 15px;
  
  img {
    width:100%;
  }
`;


const BackWrapper = styled.div`
  text-align: center;
`;

const BackButton = styled.a`
  margin: 10px;
  cursor: pointer;
  
  text-decoration:none;
  color:#FBFBF3;
  text-transform:capitalize;
  font-size:32px;
`;

function Videos({ bandData }) {
  const [selectedVideo, setSelectedVideo] = useState(false);

  const mapBandVideos = () => {
    return bandData.videos.map(video => {
      return (
        <VideoPreview onClick={() => setSelectedVideo(video)}>
          <img src={`http://img.youtube.com/vi/${video.filename}/0.jpg`} />
        </VideoPreview>
      );
    });
  };

  return (
    <div className="main-default-container">
      {!selectedVideo &&
        <div className="default-content-container">
          <VideoPreviewContainer>
            {mapBandVideos()}
          </VideoPreviewContainer>
        </div>
      }
      {selectedVideo &&
        <>
          <div
            className='video'
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0
          }}>
            <iframe
              style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
              src={`https://www.youtube.com/embed/${selectedVideo.filename}`}
              frameBorder="0"
            />
          </div>
          <BackWrapper>
            <BackButton onClick={() => setSelectedVideo(false)}>back</BackButton>
          </BackWrapper>
        </>
      }
    </div>
  );

}

export default Videos;