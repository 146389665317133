import styled from 'styled-components/macro';;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  
  @media (max-width: 900px) {
    
  }

  @media (max-width: 600px) {
    padding: 0;
  }
  
`;