import React, { useState } from 'react';
import styled from 'styled-components/macro';
import './merch.css';

const StyledMerchLink = styled.a`
    text-decoration: none;
`;

const MerchPreviewContainer = styled.div`
  text-align:center;
  display:inline-block;
  margin: 0 25px;  
`;

const MerchPreview = styled.div`
  display:inline-block;
  width:250px;
  background-color:black;
  border: 1px solid white;
  cursor: pointer;
  margin: 15px;
  padding: 10px;
  img {
    width:100%;
  }
`;

const BackWrapper = styled.div`
  text-align: center;
`;

const BackButton = styled.a`
  margin: 10px;
  cursor: pointer;
  
  text-decoration:none;
  color:#FBFBF3;
  text-transform:capitalize;
  font-size:32px;
`;

const SpotifyLink = styled.div`
  text-align: center;
  img {
    width:300px;
  }
`;

const SongTitle = styled.div`
  color:#5C7CA1;
  padding:10px;
`

const H1 = styled.h1`
  text-align:center;
  margin: 10px;
`;

function Merch({ bandData }) {

  const mapMerch = () => {
    return bandData.etsy.map(etsyItem => {
      return (
        <MerchPreview>
          <StyledMerchLink href={etsyItem.url} target='_blank' rel="noreferrer">
            <img src={`/merch/${etsyItem.thumbnail}`} />
            <SongTitle>{etsyItem.title}</SongTitle>
          </StyledMerchLink>
        </MerchPreview>
      );
    });
  };

  return (
    <div className="main-default-container">

      <div className="default-content-container">

        <H1>Merch</H1>
        <MerchPreviewContainer>
          {mapMerch()}
        </MerchPreviewContainer>
      </div>



    </div>
  );

}

export default Merch;