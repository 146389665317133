import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink as Link } from "react-router-dom";
import Email from "../../media/email.png"
import Facebook from "../../media/facebook.png"
import Soundcloud from "../../media/soundcloud.png"
import Youtube from "../../media/youtube.png"
import { Row } from '../Parts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const NavBarWrapper = styled.div`
  background-color: rgba(36, 48, 63, .9);
  text-align: center;
`;

const MainNavBar = styled(Row)`
  margin-left:auto;
  margin-right: auto;
  flex-direction: column;
  max-width:1000px;
  
`;

const Header = styled.div`
  display: flex;
`;

const BrandFlexBox = styled.div`
  display: flex;
  width: 100%;

  justify-content: center;
  @media (max-width: 900px) {
    justify-content: left;
  }
`
const NavBrand = styled(Link)`
  text-decoration: none;
  color: #FF7B12;
  text-transform: uppercase;
  text-shadow: 1px 1px black;
  font-size: 5.125rem;
  
  @media (max-width: 900px) {
    font-size: 3rem;
  }
  
  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const MobileMenuFlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MobileMenuButton = styled.div`
  display: none;
  color: #FF7B12;
  font-size: 3rem;
  text-shadow: 1px 1px black;
  @media (max-width: 600px) {
    font-size: 2rem;
  }
  
  @media (max-width: 900px) {
    display: inline-block;
  }
`;

const NavUl = styled.ul`
  margin-left:0;
  text-transform: capitalize;
  display:flex;
  justify-content: space-evenly;
  list-style-type: none;
  
  @media (max-width: 1440px) {}
  
  @media (max-width: 900px) {
    display: none;
  }
`;

const NavLi = styled.li`
  display: flex;
  align-items: center;
  
  @media (max-width: 1440px) {
  }

  @media (max-width: 900px) {
    text-align: left;
  }
`;

const IconWrapper = styled.div`
  display:flex;
`;

const NavLiIcon = styled.div`
  margin: 0 1rem;
  
  @media (max-width: 900px) {
    text-align: left;
  }
`;

const NavLinkIconImage = styled.img`
  width:25px;
`;

const NavLink = styled(Link)`
  text-decoration:none;
  color : #F97811;
  text-transform:capitalize;
  font-size:22px;
  text-shadow: 1px 1px black;
  &.selected {

    color:#E2E2E2;
  }
  
  &:hover {
    color:#E2E2E2;
  }
`;


const MobileNavMenu = styled.ul`
  margin-left:0;
  text-transform: capitalize;
  padding-left: 1rem;
  justify-content: space-evenly;
  list-style-type: none;
  
  @media (min-width: 900px) {
    display: none;
  }
`;

function Nav({ bandData, nav }) {
  const [mobileMenuVisible, toggleMobileMenu] = useState(false)

  const buildNav = () => {
    return nav.map(navItem => {
      if (navItem !== "") {
        return (
          <NavLi>
            <NavLink
              to={"/" + navItem}
              onClick={() => toggleMobileMenu(false)}
              activeClassName="selected"
            >{navItem}</NavLink>
          </NavLi>
        );
      }
    });
  };

  return (
    <NavBarWrapper>
    <MainNavBar>

      <Header>
        <BrandFlexBox>
          <NavBrand
            to={"/"}
            onClick={() => toggleMobileMenu(false)}
            activeClassName="selected"
          >{bandData.bandName}</NavBrand>
        </BrandFlexBox>

        <MobileMenuFlexBox>
          <MobileMenuButton onClick={() => toggleMobileMenu(!mobileMenuVisible)}>
            <FontAwesomeIcon icon={faBars} />
          </MobileMenuButton>
        </MobileMenuFlexBox>
      </Header>
      <NavUl>
          <NavLi>
            <NavLink
              to={"/"}
              onClick={() => toggleMobileMenu(false)}
              activeClassName="selected"
              exact={true}
            >Home</NavLink>
          </NavLi>
          {buildNav()}
          <NavLi>
            <IconWrapper>
              <NavLiIcon>
                <a href={`mailto:${bandData.email}`}>
                  <NavLinkIconImage src={Email}/>
                </a>
              </NavLiIcon>
              <NavLiIcon>
                <a href={bandData.facebook} target="_blank">
                  <NavLinkIconImage src={Facebook}/>
                </a>
              </NavLiIcon>
              <NavLiIcon>
                <a href={bandData.youtube} target="_blank">
                  <NavLinkIconImage src={Youtube}/>
                </a>
              </NavLiIcon>
              <NavLiIcon>
                <a href={bandData.soundcloud} target="_blank">
                  <NavLinkIconImage src={Soundcloud}/>
                </a>
              </NavLiIcon>
            </IconWrapper>
          </NavLi>
        </NavUl>

      {mobileMenuVisible &&
        <MobileNavMenu>
          <NavLi>
            <NavLink to={"/"} onClick={() => toggleMobileMenu(false)} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>Home</NavLink>
          </NavLi>
          {buildNav()}
          <NavLi>
            <NavLiIcon>
              <a href={`mailto:${bandData.email}`}>
                <NavLinkIconImage src={Email}/>
              </a>
            </NavLiIcon>
            <NavLiIcon>
              <a href={bandData.facebook} target="_blank">
                <NavLinkIconImage src={Facebook}/>
              </a>
            </NavLiIcon>
            <NavLiIcon>
              <a href={bandData.youtube} target="_blank">
                <NavLinkIconImage src={Youtube}/>
              </a>
            </NavLiIcon>
            <NavLiIcon>
              <a href={bandData.soundcloud} target="_blank">
                <NavLinkIconImage src={Soundcloud}/>
              </a>
            </NavLiIcon>
          </NavLi>
        </MobileNavMenu>
      }
    </MainNavBar>
    </NavBarWrapper>
  );
}

Nav.propTypes = {};

export default Nav;