import styled from 'styled-components/macro';;

export const Row = styled.div`
  display: flex;
  padding: 1rem;
  
  @media (max-width: 900px) {

  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;