import React from 'react';
import styled from 'styled-components/macro';

import { bandData } from "../../band-data"
import { Row, Col, P, UL } from '../../components/Parts';
import BulletItem from '../../components/Parts/Bullets/BulletItem';

const HomeImageWrapper = styled.div`
  width:100%;
`

const HomeImage = styled.img`
  border-radius:5px;
  width: 100%;
`;

const BulletContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const TipLink = styled.a`
    text-decoration: none;
`;

const TipWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
`;

const TipJar = styled.img`
    margin: 5px;
    width: 30%;
`;

const TipMessage = styled.p`
    color: #D2D9E2;
    font-size: 1.2rem;
    text-align: right;
    margin: 10px;
`

function Home() {
  return (
    <Row>
      <Col>
        <HomeImageWrapper>
          <HomeImage src={"/pics/" + bandData.bandBannerImage} alt="band banner"/>
        </HomeImageWrapper>
      </Col>
      <Col>
        <P>
          Liquid Fun is a high-energy band featuring catchy tunes about all aspects of life, love &
          observations. We play from the heart & love to play live.
        </P>
        <BulletContainer>
          <BulletItem>Live Performance</BulletItem>
          <BulletItem>Open Mic</BulletItem>
          <BulletItem>Studio Recordings</BulletItem>
          <BulletItem>Studio Musicians</BulletItem>
          <BulletItem>Sound Engineer</BulletItem>
          <BulletItem>Song Collaboration</BulletItem>
          <BulletItem>Networking</BulletItem>
        </BulletContainer>
        <TipLink href="https://venmo.com/u/Andrew-Greb-12">
          <TipWrapper>
            <TipMessage>
              Thanks For<br />Your Support!
            </TipMessage>
            <TipJar src="/pics/tipjar-venmo.png" alt="topjar"/>
          </TipWrapper>
        </TipLink>
    </Col>
</Row>

)
  ;
}

Home.propTypes = {};

export default Home;
