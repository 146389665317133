import styled from 'styled-components/macro';;

export const P = styled.p`
  font-size: 1.1rem;
  color: #D2D9E2;
  margin: 0 0 1rem;

  @media (max-width: 900px) {
    font-size: .9rem;
  }

  @media (max-width: 600px) {
    font-size: .8rem;
    margin: 1rem 0 1rem 0;
  }
`;