import React from 'react';
import './bio.css';
import BandMember from "./BandMember";
import { Col, P, Row } from '../../components/Parts';
import styled from 'styled-components/macro';;

const StyledCol = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const BandLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BandLogo = styled.img`
  width:75%;
`;

const BioWrapper = styled.div`
  max-width:700px;
`;

const BioText = styled.p`
  font-size: 1.1rem;
  color: #D2D9E2;
  margin: 0 0 1rem;

  @media (max-width: 900px) {
    font-size: .9rem;
  }

  @media (max-width: 600px) {
    font-size: .8rem;
    margin: 1rem 0 1rem 0;
  }
`;

const MemberRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 900px) {

    justify-content: center;
  }

  @media (max-width: 600px) {
    justify-content: center;
  }

`;

function Bio({bandData}) {
  const mapBandMemberBios = () => {
    return bandData.members.map(member => {
      return (
        <BandMember memberDetails={member}/>
      );
    });
  };

  return (
    <>
      <Row>
        <StyledCol>
          <BioWrapper>
          <BioText>
            Love, it’s our favorite addiction. We love to play, we love to perform, we love to create, we love to
            build relationships, we love…
          </BioText>
          <BioText>
            We are constantly inspired by our environment and each other. Together we
            have over 50 years of musical experience and many more in life lessons.
          </BioText>
          <BioText>
            Our influences are eclectic, random, but our sound is
            certain; a touch of country pinch of folk soaking in a broth of rock n’ roll stirred with the blues. Like
            R.E.M. and Tom Petty picked up Neil Young from Bob Dylans’s garage sale.
          </BioText>
          <BioText>
            Always driven by passion we play from the heart and
            love to play live.
          </BioText>
          </BioWrapper>
        </StyledCol>
        <StyledCol>
          <BandLogoWrapper>
            <BandLogo src={"/pics/logo.jpeg"} alt="band logo" />
          </BandLogoWrapper>
        </StyledCol>
      </Row>

      <MemberRow>
        {mapBandMemberBios()}
      </MemberRow>
    </>
  );
}

// Bio.propTypes = {
//   bandData: propType.object()
// };

export default Bio;