import React from 'react';
import './pictures.css';

function Pictures() {
  return (
    <div className="main-default-container">
      <h1>Pictures Page</h1>

      <div className="default-content-container picture-container">
        <div className="a-picture"></div>
        <div className="a-picture"></div>
        <div className="a-picture"></div>
        <div className="a-picture"></div>
        <div className="a-picture"></div>
      </div>
    </div>
  );
};

Pictures.propTypes = {};

export default Pictures;