import React from 'react';
import moment from "moment";
import AnEvent from "./AnEvent";
import Openmic from "../../media/open-mic.png"
import './events.css';
import { Col, Row } from '../../components/Parts';
import styled from 'styled-components/macro';

const OpenMicBanner = styled.img`
  width: 100%;
`;

function Events({bandData}) {

  const daysRemaining = (date) => {
    var eventDate = moment(date);
    var todaysDate = moment();
    return eventDate.diff(todaysDate, 'days');
  };

  const processEvents = () => {
    let events = bandData.shows;

    return events.map(event => {
      if (daysRemaining(event.date) >= 0) {
        return (
          <AnEvent eventDetails={event}/>
        );
      } else {
        return;
      }
    });


  };

  return (
    <Row>
      <Col>

        <OpenMicBanner className="" src={Openmic} alt='open mic' />
        <Row>
          {processEvents()}
        </Row>
      </Col>
    </Row>
  );
}

Events.propTypes = {};

export default Events;