import React from 'react';
import moment from "moment";

function AnEvent({eventDetails}) {
  return (
    <a
      href={eventDetails.location.gmaps}
      target="_blank"
      className="upcoming-show"
    >
      <div>
        <span className="upcoming-show-title">{eventDetails.venueName}</span>
        <span className="upcoming-show-date">
                    {moment(eventDetails.date).format('MMMM Do YYYY, h:mm a')}
                </span>
      </div>
      <div className="upcoming-show-address">
        {eventDetails.location.pretty}
        <a target="_blank" className="upcoming-shows-map" href={eventDetails.location.mapLink} rel="noreferrer">{' '}*MAP*</a>
      </div>
    </a>
  );
}

AnEvent.propTypes = {};

export default AnEvent;
